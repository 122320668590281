
@import '/styles/colors';
@import '/styles/mixins';
.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 25px 0;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1240px;
  width: calc(100% - 20px);
  @include body-16;
}

.menuItems {
  display: contents;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
}

.menuItemsMobile > * + * {
  margin-top: 16px;
}

.item {
  color: $gray-04;
  font-size: 16px;
  border: none;
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;

  img {
    margin-left: 10px;
  }
}

.header a:link,
.header a:visited,
.header a:hover,
.header a:active {
  text-decoration: none;
}

.dropdown {
  z-index: 10;
  position: relative;
  cursor: pointer;
  display: inline-block;

  .dropdownContent {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 321px;
    filter: drop-shadow(0px 10px 60px rgba(0, 0, 0, 0.1));
    z-index: 1;
    border-radius: 6px;
    right: 0;
    padding: 35px;

    .arrow {
      margin-top: -45px;
      width: 0;
      height: 0;
      border-left: 22px solid transparent;
      border-right: 22px solid transparent;
      border-bottom: 22px solid white;
      position: absolute;
      right: 43px;
    }
  }
}

.dropdownContent .item {
  display: block;
  margin-left: 0;
  padding: 10px;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.contentMobile {
  display: none;
}

@media (max-width: 1200px) {
  .content {
    display: none;
  }

  .contentMobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 20px);

  }

  .header {
    padding: 15px 0;

  }
}

.menuOpener {
  cursor: pointer;
}

.drawer {
  position: fixed;
  height: 100vh;
  width: 300px;
  margin-right: -300px;
  padding: 25px;
  right: 0;
  top: 0;
  background-color: white;
  transition: margin-right 0.3s ease-in-out;
  overflow-y: auto;

}

.drawerOpened {
  margin-right: 0px;
}

.drawerBackgroundVisible {
  z-index: 10000001;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease-in-out;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.drawerBackground {
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease-in-out;
}

.drawerContent {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  img {

    padding: 8px;
  }
}

.itemMobile {

  color: $blue-01;
  font-size: 14px;
  padding-top: 16px;
}

.dropdownLabel {
  padding-top: 16px;
  margin-bottom: 0;
  font-size: 14px;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: $blue-04;
  margin-top: 16px;
}

.itemActive {
  color: $blue-01;
}

.itemMobileActive {
  font-weight: bold;
}

.copyStyleButtonMenu
  {
    border-radius: 0.375rem;
    font-weight: 600;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-transition: all 250ms;
    transition: all 250ms;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
    white-space: nowrap;
    vertical-align: middle;
    line-height: 1.2;
    outline: none;
    height: 2.5rem;
    min-width: 2.5rem;
    font-size: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #3182ce;
    color: #FFFFFF;
}

