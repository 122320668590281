@import '/styles/colors';
@import '/styles/mixins';

.card {
  min-width: 20%;
  flex: 1;
  padding: 34px 40px;
  border: 1px solid $blue-04;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 280px;

  img {
    width: 45px;
  }

  .cardIcon {
    align-items: center;

  }

  .linkLabel {
    @include heading-20;
    text-align: center;
  }
}

.card:hover {
  background-color: $blue-04;
}

@media screen and (max-width: 768px) { 
  .card {
    width: 100%;
    max-width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;    
    margin-bottom: 30px;
    padding: 34px 30px;

    .linkLabel {
      @include heading-18;
      width: fit-content;
    }

    .cardIcon {
      display: flex;
      flex-direction: column;
      max-width: 65px;
    }

  }


}
