@import "/styles/colors";
@import "/styles/mixins";

.infoText {
  @include heading-18;
  color: $gray-04;
}

.infoTextLink {
  @include heading-18;
  color: $gray-02;
}

.infoTextContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid $blue-04;
  border-bottom: 1px solid $blue-04;
  padding: 32px 0 32px 0;
  margin-top: 0;
  max-width: 484px;
}

.infoTextContentWithLink {
  border-top: 0;
  border-bottom: 0;
}

.iconContent {
  justify-items: center;
  align-items: center;
  margin: 0 15px 15px 0;
  height: 35px;
  max-height: 35px;
  width: 35px;
  max-width: 35px;
  flex: 1 0 35px;

}

.space16px{
  height: 16px;
}

.link {
  align-self: center;
}

.link a {
  display: inline-flex;
}

@media screen and (max-width: 768px) {
  .infoTextContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }

  .infoText {
    font-size: 14px;
  }

}
