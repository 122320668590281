@import '/styles/colors';
@import '/styles/mixins';

.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.header {
  @include heading-20_bold;
  padding: 20px 0 20px 0;
  max-width: 235px;
}

.description {
  @include body-14;
  align-self: center;
  max-width: 240px;
  padding-bottom: 30px;
}