@import '/styles/colors';
@import '/styles/mixins';

.card {
  flex: 1;
  padding: 24px 15px 24px 15px;
  border: 1px solid $blue-04;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 259px;
  height: 115px;
}

.title {
  @include heading-18;
  color: $gray-02;
}

.description {
  @include body-16;
  color: $gray-04;
}

.cardContent {
  display: flex;
  flex-direction: column;
}

.iconContainer {
  margin-bottom: 13px;
  margin-right: 10px;
}

@media screen and (max-width: 768px) { 
  .card { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    max-width: 47%;
  }

  .iconContainer {
    margin-bottom: 0px;
  }

}