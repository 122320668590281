@import "/styles/mixins";
@import "/styles/colors";


.image {
  z-index: -1;
  background-color: $blue-01;
}

.slider {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: calc(29.68vw);
}

.slide {
  width: 100vw;
  position: relative;
  transition: opacity 0.4s ease-in-out;
  flex-shrink: 0;

  a[type="button"] {
    min-width: 280px;
    padding: unset 18px unset 18px;
    width: 0;
    p {
      color: $blue-01;
    }
  }
}

.heading {
  @include heading-16;
  color: white;
}

.title {
  @include heading-40;
  margin-top: 16px;
  color: white;
  max-width: 50%;
}

.controller {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  align-items: center;
  justify-content: center;
}

.slideButton {
  font-family: 'Poppins';
  width: 14px;
  height: 14px;
  font-weight: 400;
  font-size: 24px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  color: white;
  outline: none;
}

.slideButtonCircle {
  width: 14px;
  height: 14px;
  border-radius: 100%;
  border: white solid 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  outline: none;
}

.slideButtonActive {
  background-color: white;
  color: $blue-01;
}

.arrowController {
  height: 32px;
  margin-bottom: 16px;
  font-family: 'Poppins';
  display: flex;
  align-items: center;
  width: auto;
}

@media screen and (max-width: 1024px) {
  .heading {
    font-size: 12px;
  }

  .title {
    font-size: 24px;
    line-height: 120%;
    margin-top: 10px;
  }


  .content {
    margin: 60px auto;
  }

  .slide {
    a[type="button"] {
      min-width: 200px;
      width: 0;
      height: 50px;
      p {
        color: $blue-01;
      }
    }
  }

}

.textContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
  .slider {
    height: unset;
    width: 100%;
    height: calc(116.66vw);
  }

  .title {
    max-width: 100%;
  }

  .heading,
  .title {
    text-align: center;
  }

  .content {
    align-items: center;
    justify-content: flex-end;
  }
}
