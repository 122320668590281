@import '/styles/colors';
@import '/styles/mixins';

.markdown {
  p {
    margin: 8px 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 12px 0 6px 0;
  }

  h1 {
    @include heading-30;
  }
  h2 {
    @include heading-28;
  }
  h3 {
    @include heading-20;
  }
  h4 {
    @include heading-18;
  }
  h5 {
    @include heading-16;
  }
  h6 {
    @include heading-15;
  }

  li input {
    margin-right: 8px;
  }

  ol,
  ul {
    margin-left: 48px;
  }

  a {
    color: $blue-01
  }
}
