@import "/styles/colors";

.link {
  color: $blue-01;
}

.fraudHeader {
  font-size: 14px;
  line-height: 19.6px;
  color: #FFFFFF;
  background-color: $blue-05;
  padding: 12px 0;
  width: 100%;
}

.fraudFooter {
  font-size: 14px;
  line-height: 19.6px;
  color: #FFFFFF;
  background-color: $blue-05;
  padding: 12px 0;
  width: 100%;
  @media screen and (max-width: 768px) {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000001;
  }
}

.contentCuston {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
}

.textContent {
  width: calc(100% - 35px);
  margin-top: 5px
}

.textContent span a{
  vertical-align: sub;
}

.textContent div p{
  display: inline;
}

.textContent div {
  display: inline;
}

.textContent  a{
  text-decoration: underline;
}

.buttonContent {
  width: 32px;
  margin-left: 3px;
}

.buttonContent img {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
