@import '/styles/colors';
@import '/styles/mixins';

.cover {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}

.imageContainer {
  position: relative;
  width: 100vw;
  height: calc(29.68vw);
}

.content {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  width: 100%;
  height: 100%;
}

.content div {
  max-width: 1240px;
  width: calc(100% - 20px);
}

.textContainer {
  max-width: 539px !important;
}

.content * h3 {
  @include heading-16;
  color: #F1F0F5;
}



.content * h1 {
  @include heading-40;
  color: white;
}

.content * p {
  color: white;
  margin: 14px 0 0 0;
  opacity: 0.8;
  @include heading-20;
}

.coverMobile {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.contentMobile {
  z-index: 3;
  text-align: center;
  max-width: calc(100% - 40px);
  padding: 40px 0 75px 0;
}

.contentMobile h3 {
  @include heading-16;
  font-size: 12px;
  margin-bottom: 6px;
}

.contentMobile h1 {
  @include heading-40;
  line-height: 29px;
  font-size: 28px;
}

.contentMobile p {
  @include heading-40;
  margin: 14px 0 0 0;
  opacity: 0.8;
  font-size: 14px;
  line-height: 19px;
}

.imageContainerMobile {
  position: relative;
}

@media (max-width: 1024px)
{
  .cover {
    height: 238px;
  }

  .content * h3 {
    font-size: 12px;
    margin-bottom: 8px;
  }
  
  .content * h1 {
    font-size: 24px;
    line-height: 28px;
  }
  
  .content * p {
    font-size: 14px;
    line-height: 19px;
  }

  .arrowDown {
    margin-top: 24px;
  }

  .imageContainer {
    width: 100vw;
  }
}