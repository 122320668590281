@import '/styles/colors';
@import '/styles/mixins';

.container {
  position: relative;
}

.title {
  @include heading-34;
  color: $gray-01;
  width: 300px;
  margin-bottom: 28px;
}

.contentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;

  > div {
    display: flex;
    flex-direction: column;
    flex: 0.46;
    justify-content: space-between;
    align-content: flex-start;
  }
}

.cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}

.text {
  padding-bottom: 50px;
}

.textTitle {
  @include heading-20-bold;
  color: $gray-02;
}

.textDescription {
  @include body-16;
  color: $gray-04;
}

.subDescription {
  @extend .textDescription;
}

.form {
  padding: 30px 0 0 40px;

  .formContent {
    padding: 40px;
    background-color: white;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 24px 32px -12px rgba(0, 0, 0, 0.08);

    h1 {
      @include heading-28;
      color: $gray-01;
    }

    h2 {
      @include body-14;
      color: $gray-04;
      max-width: 310px;
    }
  }
}

.formBackground {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 563px;
  background-color: $blue-01;
  border-bottom-left-radius: 6px;
  z-index: -5;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 11px;

  .formDotsBottom {
    z-index: 1;
  }
}

.cardsLinkIcon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 120px;

  div {
    width: 100%;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1024px) {
  .formContent {
    box-shadow: 0px 15.0575px 56.4658px rgba(194, 194, 194, 0.15);
  }
}

@media screen and (max-width: 768px) {
  .title {
    @include heading-20;
    font-weight: 600;
    color: $gray-01;
    width: 100%;
    margin-bottom: 28px;
  }

  .container {
    width: 100%;
    max-width: 100%;
    align-items: center;
    align-content: center;
    text-align: center;
    padding: 15px;
  }

  .content {
    width: 100%;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    justify-items: center;
  }

  .contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 100%;
    max-width: 100%;
    text-align: center;

    > div {
      flex: 1;
    }
  }

  .form {
    padding: 0;
    text-align: center;

    .formContent {
      width: 100%;
      padding: 33px;
      margin-bottom: 60px;
      text-align: center;
      align-content: center;
      display: flex;
      flex-direction: column;

      h2 {
        @include body-14;
        align-self: center;
      }
    }
  }

  .formBackground {
    display: none;
  }

  .cardsLinkIcon {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 80%;

    div {
      width: 100%;
    }
  }

  .textTitle {
    @include heading-20-bold;
    color: $gray-02;
  }

  .textDescription {
    @include body-16;
    color: $gray-04;
    padding: 20px;
  }

  .subDescription {
    @extend .textDescription;
  }


}
